:root {
  --dagon-purple: #1D131E;
  --cthulu-green: #2DA400;
  --shaggoth-green: #1a480a;
  --innsmouth-blue: #A3BDE4;
  --nameless-white: #f4f8f5;
}

// Background
$body-bg: rgba(25, 25, 25, 0.8);

// Navbar
$dark: rgba(29, 19, 30, 1);


// Main Color variables
// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;


$primary: yellow;

body, #app, #app>div, #root {
    height: 100%;
}

html {
  font-size: 100%;
}

.nav-font {
  font-family: "CallistoMT";
  //font-size: 18px;
  font-size: 1.125rem;
}

.eldritch-font {
  font-family: 'Grechen Fuemen', cursive;
  //font-size: 75px;
  font-size: 4.688rem;
}

.compendium-title-font {
  font-family: 'Grechen Fuemen', cursive;
  //font-size: 55px -> This is a little bigger
  font-size: 3.7rem;
}

.eldritch-subtitle-font {
  font-family: "CallistoMT";
  font-style: italic;
  //font-size: 22px;
  font-size: 1.375rem;
}

.countup-font {
  //font-size: 38px;
  font-size: 2.375rem;
  font-family: CallistoMT;
  font-style: italic;
}

.compendiums-font {
  font-family: "CallistoMT";
  //font-size: 26px;
  font-size: 1.625rem;
  font-weight: bold;
}

.compendium-note-font {
  font-family: "Goudy Old Style Bold Semi";
  //font-size: 18px;
  font-size: 1.125rem;
}

.subtitle-font {
  font-family: "Goudy Old Style Semi-Expanded";
  //font-size: 26px;
  font-size: 1.625rem;
}

.entry-title-font {
  font-family: "CallistoMT";
  font-weight: bold;
  font-size: 2.2rem;
  color: var(--innsmouth-blue);
}

.entry-content-font {
  font-family: "Goudy Old Style Semi-Expanded";
  font-size: 1.125rem;
  color: var(--nameless-white);
}

.entry-source-font {
  font-family: "Goudy Old Style Semi-Expanded";
  font-size: 1rem;
}

.page-container {
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding-top: 20px;
}

.homepage-subtitle {
  width: 650px;
  padding-left: 50px;
  color: var(--nameless-white);
}

// Can't use component modules for this
// because of bootstrap
.entry-modal .modal-content {
  width: 550px;
  height: 400px;
  place-self: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-left: solid var(--shaggoth-green) 3px;
  box-sizing: content-box;
  border-right: none;
  border-top: none;
  border-bottom: none;
  text-align: left;

  background-color: var(--dagon-purple);
}


@import '~bootstrap/scss/bootstrap.scss';

