.box {
    transition: background-color 0.5s, transform 0.3s;
    border-radius: 9px;
  
    width: 300px;
    height: 300px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.box:hover {
    background-color: var(--cthulu-green);
    border-radius: 9px;
    transform: translateY(-15px); 
  }

.header {
    background-color: var(--shaggoth-green);
    height: 17%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
    border-radius: 9px 9px 0 0;
}

.image {
    background-repeat: no-repeat;
    background-size: auto;
    height: 85%;
    width: 100%;
    border-radius: 0 0 9px 9px;
}