.grid {
    align-self: center;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 100px;
    column-gap:0px;
    width: 80%;
  }
  
@media (min-width: 1350px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1950px) {
    .grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
  