.box {
    width: 500px;
    height: 400px;
    place-self: center;
    opacity: 0.8;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-left: solid var(--shaggoth-green) 3px;
    box-sizing: content-box;
    border-right: none;
    border-top: none;
    border-bottom: none;
    text-align: left;

    background-color: var(--dagon-purple);
    transition: opacity 0.5s, border-radius 0.3s;
}

.box:hover {
    opacity: 1;
    border-radius: 20px 0 0 20px; 
}

.content-container {
    height: 75%;
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */    
}
.content-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.source-container {
    margin-top: auto;
    display: flex;
    justify-content: center;
    text-align: center;
}